@import "~bootstrap/scss/bootstrap";

:root{
    --font-size:  16px;
    --big-p-size: 20px;

    --title-text: 36px;
    --title-medium-text: 28px;
    --title-small-text: 22px;
    --title-smallest-text: 18px;

    --big-text: 30px;
    --medium-text: 25px;
    --small-text: 16px;

    --padding-y-block: 15px;
    --padding-x-block: 40px;
    --margin-top: 40px;

    --orange-text: #FF3700;
    --blue-text: #3191ff;
    --light_grey_color: #A1A1A1;
    --red-text: #9fa493;
}

*{
    box-sizing: border-box;
}

html, body{
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: var(--font-size);
    background-color: #EFEFEF;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

p, a, h1, h2, h3, div{
    margin: 0;
    padding: 0;
}

h1{
    color: #fff;
}

h2, h1{
    font-size: var(--title-text);
}

.privacy h2, .terms h2{
    font-size: var(--title-small-text);
    text-transform: uppercase;
}

.privacy h3, .terms h3{
    font-size: var(--title-smallest-text);
    font-weight: 700;
}

a{
    text-decoration: none;
}

a,p{
    font-size: var(--font-size);
}

h1, h2, h3{
    color: #30343c;
}

p, a{
    color: #302c34;
}

em{
    font-weight: 700;
}

.center_vertical_content{
    min-height: calc(100dvh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.grey_text{
    color: var(--light_grey_color);
}

.text_mark{
    background-color: #dedede; padding: 1px 5px; display: inline-block
}

// NAVBAR

.navbar{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #EFEFEF;
    z-index: 9;
    width: 100%;
}

.navbar-nav a{
    position: relative;
    transition: 0.3s ease;
}

.navbar-nav a::before{
    transform: scaleX(0);
    transform-origin: bottom left;
    content: "";
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: #000;
    z-index: -1;

    transition: transform 0.3s ease;
}

.navbar-nav a:hover{
    color: #fff;
    text-decoration: none;
}

.navbar-nav a:hover::before {
    transform: scaleX(1);
}

.nav-link{
    padding: 0 0.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media all and (min-width: 992px) and (max-width: 1200px){
    .nav-link{
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
}

a.nav-link.active{
    color: #fff !important;
    background: #000;
    position: relative;
}

a.nav-link.active::after{
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: var(--red-text);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
}

img.logo{
    width: 100%;
    max-width: 200px;
    position: relative;
    top: 3px;
}

@media all and (max-width: 991px){
    .nav-item{
        margin-top: 25px;
    }
}


/*
	* Lang Switcher
*/
.lang{
    position: absolute;
    top: 0; right: 0;
    margin: 30px 40px;
}

.lang_switcher{
    //border: 1px solid #a1a1a1;
    //border-radius: 3px;
    padding: 5px;
}

.flag{
    margin-right: 15px;
    cursor: pointer;
}

.flag_icon{
    width: 30px;
    height: auto;
}

.lang img{
    display: block;
}

.other_langs{
    visibility: hidden;
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.other_langs.visible{
    visibility: visible;
    transform: scale(1);
}

.other_langs > a{
    display: block
}
/*
************************
	* Page home
************************
*/
.home{
    margin-top: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

@media all and (min-width:491px){
    .home_writing_text_br{
        display:none;
    }
}

.slogan{
    font-size: var(--big-p-size);
}

.home_column_2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.applications_btn{
    border: 1px solid #E1E1E1;
    color: #606060;
    padding: 0;
    background: #E8E8E8;
}

.applications_btn_heypster{
    padding: 5px 5px 5px 15px;
    display: block;
    font-size: 0.8rem;
    line-height: 1;
}

.applications_btn_text{
    padding: 8px 5px 8px 15px;
    display: block;
}

.contact_email{
    color: var(--red-text);
    font-size: 0.8rem;
}

.thin{
    font-weight: 300;
}

.hr_heypster{
    margin: 0;
}

/*
************************
	* Page Accueil - Animation
************************
*/
.home_gif_container.gif_line_1{
    margin-top: 580px;
}

.home_gif_container{
    position: relative;
    background: #E8E8E8;

    &::before, &::after{
        position: absolute;
        z-index: 2;
        content: "";
        left: 0;
        right: 0;
        height: 1px;
        background-color: #DCDCDC;
        contain: strict;
    }

    &::before{
        top: 0;
    }

    &::after{
        top: 25px;
    }

}

.home_gif_line{
    max-width: 1440px;
    position: relative;
    margin-inline: auto;
    padding: 0 25px;
    padding-top: 1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    transition: 500ms ease-in-out;
}

.home_gif_line:has(.home_gif_col_1:hover){
    grid-template-columns: 1fr 0fr;
    padding-right: 0;

    .home_gif_content_box{
        max-height: 100%;
    }
    .home_gif_crop{
        width: 300px;
        height: auto;
    }

    .hidden_text{
        display: block;
    }

    .home_gif_col_2{
        border: none;
    }
}

.home_gif_line:has(.home_gif_col_2:hover){
    grid-template-columns: 0fr 1fr;
    padding-left: 0;

    .home_gif_content_box{
        max-height: 100%;
    }
    .home_gif_crop{
        width: 300px;
        height: auto;
    }

    .hidden_text{
        display: block;
    }

    .home_gif_col_1{
        border: none;
    }
}

.home_gif_line:not(:has(.home_gif_col_1:hover)) .home_gif_col_2{
    width: 100%;
}


.home_gif_line:has(.home_gif_col_1:hover) .home_gif_col_2{
    max-width: 0;
    height: 0;
}

.home_gif_line:not(:has(.home_gif_col_2:hover)) .home_gif_col_1{
    width: 100%;
}

.home_gif_line:has(.home_gif_col_2:hover) .home_gif_col_1{
    max-width: 0;
    height: 0;
}

.home_gif_col{
    padding-top: 25px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.home_gif_border{
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
}

.home_gif_box{
    overflow: hidden;
    height: 100%;
}

.home_gif_title_box{
    position: absolute;
    top: 0;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 25px;
        background-color: #000;
        z-index: -1;
        transition: top 0.3s ease-in-out;
    }

    p{
        font-size: 0.8rem;
        color: var(--light_grey_color);
        padding: 0 15px;
        line-height: 25px;
    }
}

.home_gif_content_box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    align-items: center;
    justify-content: center;
/*    transition: 500ms; */

    .hidden_text{
        display: none;
    }

    .home_gif_crop{
        width: 100%;
        height: calc(300px - 25px);
        object-fit: cover;
        object-position: top center;
        transition: 500ms;
    }
    p{
        max-width: 450px;
        transition: 500ms;
    }
}

.home_gif_col{
    &:hover .home_gif_title_box::before{
        top: 0;
    }
}

@media all and (max-width: 991px){
    .home{
        position: static;
    }
    .home_gif_container.gif_line_1{
        margin-top: 0;
    }
}

@media all and (max-width: 980px){
    .home_gif_line{
        grid-template-columns: 1fr;
        gap:0;
    }

    .home_gif_crop{
        width: 300px !important;
    }

    .home_gif_col_2 .home_gif_title_box{
        border-top: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC;
    }

}

@media all and (max-width: 768px){
    .home_gif_content_box{
        grid-template-columns: 1fr;
    }

    .home_gif_content_box p{
        margin: auto;
    }
}

/*
************************
	* Page documentation
************************
*/
#overview{
    padding-top: calc(3rem + 90px);
}

.documentation_nav{
    position: relative;
    width: 280px;
    margin-right: 50px;
}

.documentation_sidebar{
    position: sticky;
    background-color: rgba(225, 225, 225, 0.5);
    top: calc(3rem + 95px);
}

.gitlab{
    position: sticky;
    top: calc(3rem + 95px + 375px);
}

.documentation_nav_title{
    font-size: 1.2rem;
    font-weight: 700;
}

.documentation_sidebar .active{
    color: var(--blue-text);
}

.documentation_nav a:hover{
    text-decoration: none;
}

.documentation_img_presentation{
    width: 666px;
    height: auto;
    max-width: 100%;
}

.documentation_text{
    flex: 1 1 365px;
}

@media all and (min-width: 769px){
    .documentation_text{
        max-width: calc(100% - 248px);
    }
}

.documentation_text a{
    color: var(--blue-text);
}

// WEB
.documentation_web_sdk_img_1{
    width: 666px;
    height: auto;
    max-width: 100%;
}

.documentation_web_sdk_img_2{
    width: 969px;
    height: auto;
    max-width: 100%;
}

@media all and (max-width: 991px){
    pre{
        max-width: 340px;
    }

    .documentation_nav{
        width: 200px;
    }
}

@media all and (max-width: 768px){
    #documentation{
        flex-direction: column;
    }
    pre{
        max-width: 100%;
    }
    .documentation_nav{
        width: 280px;
    }
}


/*
************************
	* Page Pricing
************************
*/

.red_text{
    color: var(--red-text);
}

.pricing_container{
    margin-top: calc(3rem + 90px);
    background: #E8E8E8;
}

.pricing_grid{
    display: grid;
    width: 100%;
    grid-template-columns: minmax(20px, auto) 25px minmax(250px,500px) minmax(500px, 1000px) 25px minmax(20px, auto);
    grid-template-rows: auto;
}

@media all and (max-width: 980px){
    .pricing_grid{
        grid-template-columns: 0 25px minmax(280px,1fr) 2fr 25px 0;
    }
}

.pricing_col, .pricing_price_block, .pricing_block_price_decoration{
    outline: 1px solid #DCDCDC;
}

.column_black_bg{
    background: #212121;
    padding-top: 2px;
    padding-bottom: 2px;
    //outline: 1px solid #EFEFEF;
}

.column_grey_bg{
    background: #8E8E8E;
    padding-top: 2px;
    padding-bottom: 2px;
    //outline: 1px solid #EFEFEF;
}

.column_title{
    font-size: 0.8rem;
    color: #fff !important;
    line-height: 1;
    outline: 1px solid #DCDCDC;
}

.pricing_block_decoration{
    display: block;
    width: 100%;
    height: calc(0.8rem + 4px);
    outline: 1px solid #DCDCDC;
}

.pricing_list_price_grid{
    display: grid;
    grid-template-columns: minmax(150px, 235px) 25px minmax(150px, 235px) 25px minmax(150px, 235px);
    grid-template-rows: auto auto;
}

@media all and (max-width: 930px){
    .pricing_list_price_grid{
        width: 100%;
        height: 100%;
        place-content: center;
        grid-template-columns: minmax(200px, 250px);
        grid-template-rows: repeat(3, auto);
        row-gap: 20px;
    }

    .pricing_block_price_decoration{
        display: none;
    }
}

@media all and (max-width: 680px) {

    .pricing_container {
        background: #EFEFEF;
    }

    .pricing_grid {
        width: 80%;
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, auto);
        row-gap: 50px;
        margin: auto;
        background: #EFEFEF;
    }

    .pricing_info, .pricing_price {
        background: #E8E8E8;
    }

    .pricing_col_1, .pricing_col_2, .pricing_col_5, .pricing_col_6 {
        display: none;
    }

    .pricing_price_block {
        outline: 1px solid #DEDEDE;
    }
}

.pricing_col-colum_title{
    top: 0;
    left: 0;
    width: 100%;
}

/*
    * Terms
 */

.terms{
    margin-top: calc(3rem + 90px);
}

/*
    * Terms
 */

.privacy{
    margin-top: calc(3rem + 90px);
}

/*
    * EFFECTS
 */

/* BLINKING LETTERS EFFECT */
.flicker-link {
    white-space: pre; /* Conserve les espaces */
}

.flicker-letter {
    display: inline-block;
    opacity: 1;
}

@keyframes flicker {
    0% { opacity: 1; }
    50%{opacity: 0.15}
    100%{opacity: 1}
}

.flicker-letter.flickering {
    animation-name: flicker;
    animation-duration: calc(var(--r1) * 0.2s);
    //animation-duration: calc(var(--r1) * 0.18s);
    animation-timing-function: cubic-bezier(.22, 1, .36, 1);
    animation-delay: calc(var(--r2) * 0.5s);
}

.demo_lang, .demo_lang a{
    font-size: 1.4rem;
}

.demo_list{
    font-size: 1rem;
}

.demo_image{
    max-width: 350px;
}


#heypster-popup-overlay{
    max-width: 100%;
    position: static !important;
    width: auto !important;
    height: 600px !important;
    background: none !important;
}

.heypster-sdk-v2 #heypster-popup-overlay{
    width: 350px !important;
    height: 750px !important;
}
